import React from "react"

export const WelcomingImage: React.FC<{ src: string; text: string }> = ({
  src,
  text,
}) => {
  return (
    <div className="relative flex w-full flex-grow flex-col items-center lg:mt-20 lg:flex lg:max-w-homepage-image-container lg:flex-row lg:justify-start">
      <img
        className="mb-20 flex w-full flex-row justify-center lg:relative lg:z-0 lg:flex lg:w-homepage-image lg:w-full"
        src={src}
        alt={""}
      />
      <div className="font-display flex w-10/12 flex-col items-center px-4 text-homepageMantraText sm:justify-center md:px-0 lg:absolute lg:top-0 lg:right-0 lg:z-30 lg:flex lg:w-homepage-image-box lg:flex-col lg:items-center lg:space-y-8">
        <h2 className="mb-8 w-10/12 text-5xl font-semibold md:text-6xl lg:mb-4 lg:w-7/12 lg:pl-12 lg:pt-14 lg:text-7xl">
          {text}
        </h2>
      </div>
    </div>
  )
}

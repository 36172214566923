import { Eversports } from "../Eversports/Eversports"
import { CampaignPreview } from "../CampaignPost/CampaignPost"
import React from "react"
import { Campaign } from "../../types"

interface TwoColumnBoxProps {
  headings: { left: string; right: string }
  campaigns: Campaign[]
}

export const TwoColumnBox: React.FC<TwoColumnBoxProps> = ({
  campaigns,
  headings,
}) => {
  return (
    <div className="mx-16 mt-4 flex h-full flex-col items-center bg-white lg:flex lg:w-10/12 lg:flex-row lg:items-start lg:justify-center">
      <HomepageSideContainer text={headings.left} width="w-7/12">
        <Eversports
          title="overview of next courses"
          widgetCode="t4ez9i/day-event-calendar"
          height={500}
          className="z-0 w-full"
        />
      </HomepageSideContainer>
      <div className="lg:my-18 lg:w-0.5 lg:self-stretch lg:bg-gray-200" />
      <HomepageSideContainer text={headings.right} width="w-5/12">
        {campaigns.map(({ fields: { content }, title, slug }, index) => (
          <CampaignPreview
            key={index}
            slug={slug}
            title={title}
            html={`${content ? content.slice(0, 240) : ""}...`}
          />
        ))}
      </HomepageSideContainer>
    </div>
  )
}

const HomepageSideContainer: React.FC<{ text: string; width: string }> = ({
  text,
  width,
  children,
}) => (
  <div
    style={{ flexBasis: "max-content" }}
    className={`w-full lg:${width} z-0 flex flex-col items-center bg-white  px-12 lg:pt-12`}
  >
    <SideHeader>{text}</SideHeader>
    {children}
  </div>
)

const SideHeader: React.FC = ({ children }) => {
  return (
    <h3 className="mt-10 flex w-full flex-col items-start text-4xl tracking-widest text-homepageMantraText lg:mb-4 lg:mt-3.5 lg:items-center">
      {children}
    </h3>
  )
}

import React, { useCallback, useEffect, useState } from "react"

interface CollageImageProps {
  index: number
  src: string
  size: {
    width: number
    marginLeft: number
    marginTop: number
  }[]
  IndexOrOurRoom: boolean
}

export const ImageCollage: React.FC<CollageImageProps> = ({
  src,
  index,
  size,
  IndexOrOurRoom,
}) => {
  const [hover, setHover] = useState(false)
  const [scale, setScale] = useState(false)
  const { width: imageWidth, marginLeft, marginTop } = size[index]
  useEffect(() => {
    const timer = setTimeout(() => {
      setScale(true)
    }, 1000 * index)
    return () => clearTimeout(timer)
  }, [index])

  const handleTransitionEnd = useCallback(() => {
    if (!scale) {
      setScale(true)
    }
    if (scale) {
      setScale(false)
    }
  }, [scale])

  const leftHover = () => {
    if (hover) {
      return setHover(false)
    }
  }
  const isHover = () => {
    if (!hover) {
      return setHover(true)
    }
  }

  const touchEnabled = isTouchEnabled()
  return (
    <div
      className={`absolute top-0 left-0 right-0 z-0 mx-auto transform flex-row ease-in-out ${
        touchEnabled
          ? scale
            ? "scale-110 transform transition delay-200 duration-4900"
            : "scale-100 duration-4900"
          : hover
          ? "scale-110 transform transition duration-1000"
          : "scale-100 duration-1000"
      }`}
      style={{
        width: `${imageWidth}%`,
        left: IndexOrOurRoom
          ? marginLeft * (index % 2 === 0 ? -1 : 1)
          : marginLeft * (index % 2 === 0 ? -1 : 1),
        top: marginTop,
      }}
      onMouseEnter={touchEnabled ? undefined : isHover}
      onMouseLeave={touchEnabled ? undefined : leftHover}
      onTransitionEnd={touchEnabled ? handleTransitionEnd : undefined}
    >
      <img
        src={src}
        className="z-0 w-full border-4 border-white shadow-test"
        alt=""
      />
    </div>
  )
}
const isTouchEnabled = () => {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  )
}

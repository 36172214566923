import React from "react"

interface EversportsProps {
  title: string
  widgetCode: string
  height: number
  className: string
}

export const Eversports: React.FC<EversportsProps> = ({
  title,
  widgetCode,
  height,
  className,
}) => {
  return (
    <div className={className}>
      <iframe
        title={title}
        width="100%"
        height={height}
        src={`https://www.eversports.de/widget/w/${widgetCode}`}
        frameBorder="0"
      />
    </div>
  )
}

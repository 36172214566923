import { graphql } from "gatsby"
import React from "react"
import { Layout } from "../components/Layout/Layout"
import { ImageCollageContainer } from "../components/ImageCollageContainer/ImageCollageContainer"
import { Eversports } from "../components/Eversports/Eversports"
import { Campaign, GraphqlNodeArray } from "../types"
import { TwoColumnBox } from "../components/TwoColumnBox/TwoColumnBox"
import { WelcomingImage } from "../components/WelcomingImage/WelcomingImage"

interface IndexProps {
  data: {
    cms: {
      page: {
        fields: {
          mainheading: string
          image: {
            sourceUrl: string
          }
          firstImage: {
            sourceUrl: string
          }
          secondImage: {
            sourceUrl: string
          }
        }
      }
      collageImages: {
        nodes: {
          collageImageFields: {
            image: {
              sourceUrl: string
              mediaDetails: {
                width: number
                height: number
              }
            }
            radiobutton: string
          }
        }[]
      }
      campaigns: GraphqlNodeArray<Campaign>
    }
  }
}

export const Index: React.FC<IndexProps> = ({ data }) => {
  const dataVariable = data.cms.page.fields

  return (
    <Layout secondLayout={false}>
      <WelcomingImage
        src={dataVariable.image.sourceUrl}
        text={dataVariable.mainheading}
      />
      <TwoColumnBox
        headings={{
          left: "Unsere heutigen Kurse:",
          right: "Aktionen",
        }}
        campaigns={data.cms.campaigns.nodes}
      />

      <ImageCollageContainer
        imageContainer={data.cms.collageImages.nodes.filter(
          ({ collageImageFields: { radiobutton } }) =>
            radiobutton === "Homepage",
        )}
        IndexOrOurRoom={true}
      />
      <div className="mt-0 flex w-full flex-col items-center">
        <div className="flex flex-col items-center pb-64 md:w-10/12 lg:w-full">
          <Eversports
            title="week overview of courses"
            widgetCode="t4ez9i"
            height={1000}
            className="border-1 relative flex w-full flex-row justify-center bg-white pt-52 md:mt-32 lg:w-full lg:px-40 lg:pt-20 lg:pb-12"
          />
        </div>
      </div>
    </Layout>
  )
}

export default Index
export const query = graphql`
  query Index {
    cms {
      page(id: "/homepage", idType: URI) {
        fields {
          mainheading
          image {
            sourceUrl
          }
        }
      }
      collageImages(where: { orderby: { field: DATE, order: ASC } }) {
        nodes {
          collageImageFields {
            image {
              sourceUrl
              mediaDetails {
                width
                height
              }
            }
            radiobutton
          }
        }
      }

      campaigns(first: 2) {
        nodes {
          slug
          title
          fields {
            content
          }
        }
      }
    }
  }
`

import React, { useEffect, useState } from "react"
import { ImageCollage } from "../ImageCollage/ImageCollage"

interface ImageCollageProps {
  imageContainer: {
    collageImageFields: {
      image: {
        sourceUrl: string
        mediaDetails: { width: number; height: number }
      }
      radiobutton: string
    }
  }[]
  IndexOrOurRoom: boolean
}

enum ScreenSize {
  small = "small",
  medium = "medium",
  ipadPro = "ipadPro",
  large = "large",
}

const getSize = (
  screenSize: ScreenSize,
  imageFormat: boolean,
  IndexOrOurRoom: boolean,
): { width: number; marginTop: number; marginLeft: number }[] => {
  if (IndexOrOurRoom) {
    switch (screenSize) {
      case ScreenSize.small:
        return [
          { width: 40, marginLeft: 0, marginTop: 0 },
          { width: 30, marginLeft: 150, marginTop: 72 },
          { width: 35, marginLeft: 85, marginTop: 95 },
          { width: 20, marginLeft: 280, marginTop: 20 },
          { width: 25, marginLeft: 210, marginTop: 50 },
        ]

      case ScreenSize.medium:
        return [
          { width: 40, marginLeft: 0, marginTop: 0 },
          { width: 30, marginLeft: 300, marginTop: 150 },
          { width: 35, marginLeft: 180, marginTop: 190 },
          { width: 20, marginLeft: 550, marginTop: 50 },
          { width: 25, marginLeft: 450, marginTop: 100 },
        ]
      case ScreenSize.ipadPro:
        return [
          { width: 40, marginLeft: 0, marginTop: 0 },
          { width: 30, marginLeft: 400, marginTop: 200 },
          { width: 35, marginLeft: 245, marginTop: 270 },
          { width: 20, marginLeft: 750, marginTop: 40 },
          { width: 25, marginLeft: 600, marginTop: 120 },
        ]
      case ScreenSize.large:
        return [
          { width: 40, marginLeft: 0, marginTop: 0 },
          { width: 30, marginLeft: 520, marginTop: 250 },
          { width: 35, marginLeft: 245, marginTop: 330 },
          { width: 20, marginLeft: 920, marginTop: 40 },
          { width: 25, marginLeft: 780, marginTop: 120 },
        ]
    }
  }
  if (!IndexOrOurRoom) {
    switch (screenSize) {
      case ScreenSize.small:
        return [
          { width: 40, marginLeft: 0, marginTop: 0 },
          { width: 30, marginLeft: 140, marginTop: 70 },
          { width: 35, marginLeft: 95, marginTop: 90 },
          { width: 20, marginLeft: 190, marginTop: 20 },
          { width: 25, marginLeft: 210, marginTop: 45 },
        ]

      case ScreenSize.medium:
        return [
          { width: 40, marginLeft: 0, marginTop: 0 },
          { width: 30, marginLeft: 200, marginTop: 160 },
          { width: 35, marginLeft: 220, marginTop: 200 },
          { width: 20, marginLeft: 440, marginTop: 90 },
          { width: 25, marginLeft: 480, marginTop: 120 },
        ]
      case ScreenSize.large:
        return [
          { width: 40, marginLeft: 0, marginTop: 0 },
          { width: 30, marginLeft: 400, marginTop: 200 },
          { width: 35, marginLeft: 245, marginTop: 250 },
          { width: 20, marginLeft: 590, marginTop: 100 },
          { width: 25, marginLeft: 650, marginTop: 120 },
        ]
    }
  }
  return [
    { width: 0, marginLeft: 0, marginTop: 0 },
    { width: 0, marginLeft: 0, marginTop: 0 },
    { width: 0, marginLeft: 0, marginTop: 0 },
    { width: 0, marginLeft: 0, marginTop: 0 },
    { width: 0, marginLeft: 0, marginTop: 0 },
  ]
}
export const ImageCollageContainer: React.FC<ImageCollageProps> = ({
  imageContainer,
  IndexOrOurRoom,
}) => {
  const [size, setSize] = useState<ScreenSize | undefined>(undefined)
  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 750) {
        setSize(ScreenSize.small)
      }
      if (window.innerWidth <= 1023 && window.innerWidth > 750) {
        setSize(ScreenSize.medium)
      }
      if (
        window.innerWidth >= 1023 &&
        window.innerWidth <= 1250 &&
        IndexOrOurRoom
      ) {
        setSize(ScreenSize.ipadPro)
      }
      if (window.innerWidth > 1023 && !IndexOrOurRoom) {
        setSize(ScreenSize.large)
      }
      if (window.innerWidth > 1250 && IndexOrOurRoom) {
        setSize(ScreenSize.large)
      }
    }
    handleWindowResize()
    window.addEventListener("resize", handleWindowResize)

    return () => window.removeEventListener("resize", handleWindowResize)
  }, [IndexOrOurRoom])

  return (
    <div
      className={`z-0 w-full pt-64 lg:p-0 ${
        IndexOrOurRoom ? "mt-44" : "lg:mt-20"
      } relative sm:pt-0`}
      style={{
        height:
          size === ScreenSize.small
            ? 400
            : size === ScreenSize.medium
            ? 600
            : ScreenSize.large
            ? 800
            : 800,
      }}
    >
      {size &&
        imageContainer.map(
          (
            {
              collageImageFields: {
                image: {
                  sourceUrl,
                  mediaDetails: { width, height },
                },
              },
            },
            index,
          ) => {
            const imageFormat = width / height > 1
            return (
              <ImageCollage
                key={index}
                index={index}
                src={sourceUrl}
                size={getSize(size, imageFormat, IndexOrOurRoom)}
                IndexOrOurRoom={IndexOrOurRoom}
              />
            )
          },
        )}
    </div>
  )
}

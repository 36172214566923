import { Link } from "gatsby"
import React, { ReactNode } from "react"

interface HomepagePromotionProps {
  header: ReactNode
  campaigns: { slug: string; title: string; content: string }[]
}

export const CampaignPreview: React.FC<{
  slug: string
  title: string
  html: string
}> = (props) => {
  return (
    <Link
      to={"/" + props.slug}
      className="mb-4 flex w-full flex-col items-start"
    >
      <div className="my-4 w-full items-start font-extralight tracking-widest md:tracking-wide">
        <span className="w-full text-lg text-headings md:text-xl">
          {props.title}
        </span>
      </div>
      <div
        className="w-full font-titilliumWeb text-base font-light leading-7 text-font"
        dangerouslySetInnerHTML={{ __html: props.html }}
      />
    </Link>
  )
}
